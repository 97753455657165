<script setup lang="ts">
const props = defineProps<{
  fields: {
    logo: VoixSelectFieldInterface
    color: VoixColorFieldInterface
    size: VoixNumberFieldInterface
    position: VoixSelectFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Logo', group: 'Branding' },
  slots: [{ name: 'default', allowedElements: [] }],
  description: '',
  fields: {
    logo: {
      type: 'select',
      label: 'Logo',
      options: {
        LogosAtlantisLogo: 'Atlantis Logo',
        LogosReefLogo: 'Reef Logo',
      },
      default: 'LogosAtlantisLogo',
    },

    color: {
      type: 'color',
      label: 'Color',
      enabled: false,
    },

    size: {
      type: 'number',
      label: 'Size',
      default: '200',
    },

    position: {
      type: 'select',
      label: 'Position',
      options: {
        left: 'Left',
        center: 'Center',
        right: 'Right',
      },
    },
  },
  templates: [{
    label: 'Slider Container',
    fields: {
      logo: {
        value: 'LogosAtlantisLogo',
      },
      color: {
        value: '#000000',
        enabled: true,
      },
      size: {
        value: '100',
      },
    },
  }],
})

const logoStyles = computed(() => {
  const styles = {}
  if (props.fields.color.enabled)
    styles.color = props.fields.color.value

  if (props.fields.size.value)
    styles.width = `${props.fields.size.value}px`

  return styles
})
</script>

<template>
  <div
    class="flex " :class="{
      'justify-start': fields.position === 'left',
      'justify-center': fields.position === 'center',
      'justify-end': fields.position === 'right',
    }"
  >
    <LazyLogosAtlantisLogo v-if="fields.logo.value === 'LogosAtlantisLogo'" :style="logoStyles" />
    <LazyLogosReefLogo v-if="fields.logo.value === 'LogosReefLogo'" :style="logoStyles" />
  </div>
</template>
